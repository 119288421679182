import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import Ajax from '../../common/ajax';
import Modal from '../../common/containers/Modal';
import { AlertError } from '../../common/components/Alert';
import Select from '../../common/components/form/Select';
import Input from '../../common/components/form/Input';
import HtmlUtils from '../../common/htmlutils';
import Row from '../../common/containers/Row';
import Col from '../../common/containers/Col';
import { withNamespaces } from 'react-i18next';
import { getPagSeguroMessage } from './PagSeguroMessages';
import LocaleUtils from '../../common/LocaleUtils';

class PagSeguroCreditcardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardOptions: {},
      cardBrand: {},
      installments: [],
      pagseguroSession: null,
      processing: false,
      showModalCards: false,
      installment_obj_string: '',
      cardValidationErrors: [],
      form: {
        sender_hash: '',
        card_token: '',
        installment_quantity: 1,
        installment_value: '',
        total_amount: '',
        cardnumber: '',
        validity: '',
        cvc: '',
      },
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onInstallmentChange = this.onInstallmentChange.bind(this);
    this.fetchCardOptions = this.fetchCardOptions.bind(this);
    this.fetchBrand = this.fetchBrand.bind(this);
    this.fetchInstallments = this.fetchInstallments.bind(this);
    this.handleCardErrors = this.handleCardErrors.bind(this);
    this.createToken = this.createToken.bind(this);
    this.cardnumberRef = React.createRef();
    this.formRef = React.createRef();
    this.lastFetchedCardBin = null;
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchPagSeguroSession();
    this.props.createTokenFuncRef(this.createToken);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // Needs to reload the installments if the card brand changes.
    if (
      JSON.stringify(this.state.cardBrand) !==
        JSON.stringify(prevState.cardBrand) &&
      Object.keys(this.state.cardBrand).length > 0
    ) {
      this.fetchInstallments();
    }

    // Needs to reload the installments if the price value changes.
    if (
      this.state.installments.length &&
      prevProps.price !== this.props.price
    ) {
      this.fetchInstallments();
    }

    if (
      this.props.onProcessing &&
      prevState.processing !== this.state.processing
    ) {
      this.props.onProcessing(this.state.processing);
    }
  }

  setStateIfMounted(state, callback) {
    if (this._mounted) {
      this.setState(state, callback);
    }
  }

  onInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setStateIfMounted(
      (state) => ({
        ...state,
        form: {
          ...state.form,
          [name]: value,
        },
      }),
      () => {
        this.props.onChange(this.getProcessedForm());
      }
    );
  }

  getProcessedForm() {
    return {
      ...this.state.form,
      validity: this.getValidityFullYear(),
    };
  }

  getValidityFullYear() {
    if (this.state.form.validity) {
      const [month, year] = this.state.form.validity.split('/');
      return `${month}/20${year}`;
    }
    return '';
  }

  onInstallmentChange(event) {
    event.persist();
    const installment =
      (event.target.value && JSON.parse(event.target.value)) || {};
    this.setStateIfMounted((state) => ({
      ...state,
      installment_obj_string: event.target.value,
      form: {
        ...state.form,
        installment_quantity: installment.quantity,
        installment_value: installment.installmentAmount,
        total_amount: installment.totalAmount,
      },
    }));
  }

  fetchPagSeguroSession() {
    this.setStateIfMounted((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}checkouts/pagseguro_session/`;
    Ajax.get(url)
      .done((data) => {
        this.setStateIfMounted((state) => ({
          ...state,
          pagseguroSession: data,
        }));
        PagSeguroDirectPayment.setSessionId(data);
        this.fetchCardOptions();
      })
      .fail(() => {
        this.setStateIfMounted((state) => ({ ...state, processing: false }));
        const { i18n } = this.props;
        this.handleCardErrors(
          { error: true },
          i18n.t('Não foi possível iniciar a sessão com o PagSeguro.')
        );
      })
      .always(() => {
        if (this.props.onPagSeguroSessionComplete) {
          this.props.onPagSeguroSessionComplete();
        }
      });
  }

  fetchCardOptions() {
    PagSeguroDirectPayment.getPaymentMethods({
      amount: String(this.props.price).replace(',', '.'),
      success: (response) => {
        const cardOptions = response.paymentMethods.CREDIT_CARD.options;
        this.setStateIfMounted((state) => ({
          ...state,
          cardOptions,
          processing: false,
        }));
        this.cardnumberRef.current.focus();
      },
      error: (response) => {
        const { i18n } = this.props;
        this.handleCardErrors(
          response,
          i18n.t('Não foi possível recuperar as opções de pagamento.')
        );
      },
      complete: (response) => {
        this.setStateIfMounted((state) => ({ ...state, processing: false }));
      },
    });
  }

  fetchBrand() {
    if (this.state.form.cardnumber < 10) {
      return;
    }

    const cardBin = this.state.form.cardnumber.split(' ').join('').slice(0, 6);

    if (this.lastFetchedCardBin === cardBin) {
      // Avoid duplicate fetches.
      return;
    }

    this.setStateIfMounted((state) => ({
      ...state,
      processing: true,
      cardValidationErrors: [],
    }));

    PagSeguroDirectPayment.getBrand({
      cardBin: cardBin,
      success: (response) => {
        this.setStateIfMounted((state) => ({
          ...state,
          cardBrand: response.brand,
          processing: false,
        }));
        this.lastFetchedCardBin = cardBin;
      },
      error: (response) => {
        this.setStateIfMounted((state) => ({
          ...state,
          cardBrand: {},
          processing: false,
          installments: [],
        }));
        this.lastFetchedCardBin = null;
        const { i18n } = this.props;
        this.handleCardErrors(
          response,
          i18n.t(
            'Não foi possível recuperar informações da bandeira do cartão.'
          )
        );
      },
    });
  }

  fetchInstallments() {
    this.setStateIfMounted((state) => ({ ...state, processing: true }));
    PagSeguroDirectPayment.getInstallments({
      amount: String(this.props.price).replace(',', '.'),
      // maxInstallmentNoInterest: 3,
      brand: this.state.cardBrand.name,
      success: (response) => {
        const installments = response.installments[
          this.state.cardBrand.name
        ].map((inst) => {
          const newInst = Object.assign({}, inst);
          newInst.installmentAmount = parseFloat(
            newInst.installmentAmount
          ).toFixed(2);
          newInst.totalAmount = parseFloat(newInst.totalAmount).toFixed(2);
          return newInst;
        });
        this.setStateIfMounted((state) => ({
          ...state,
          processing: false,
          installments,
          installment_obj_string:
            installments.length && JSON.stringify(installments[0]),
          form: {
            ...state.form,
            installment_quantity:
              installments.length && installments[0].quantity,
            installment_value:
              installments.length && installments[0].installmentAmount,
            total_amount: installments.length && installments[0].totalAmount,
          },
        }));
      },
      error: (response) => {
        const { i18n } = this.props;
        this.handleCardErrors(
          response,
          i18n.t('Não foi possível recuperar as opções de parcelamento.')
        );
        this.setStateIfMounted((state) => ({
          ...state,
          processing: false,
          installments: [],
        }));
      },
    });
  }

  createToken() {
    this.setStateIfMounted((state) => ({
      ...state,
      processing: true,
      cardValidationErrors: [],
    }));
    const [month, year] = this.getValidityFullYear().split('/');
    PagSeguroDirectPayment.createCardToken({
      cardNumber: this.state.form.cardnumber,
      cvv: this.state.form.cvc,
      expirationMonth: month,
      expirationYear: year,
      success: (response) => {
        this.setStateIfMounted(
          (state) => ({
            ...state,
            processing: false,
            form: {
              ...state.form,
              card_token: response.card.token,
              sender_hash: PagSeguroDirectPayment.getSenderHash(),
            },
          }),
          () => {
            this.props.onChange(this.getProcessedForm());
            this.props.onCreditCardValidated(true);
          }
        );
      },
      error: (response) => {
        this.setStateIfMounted((state) => ({ ...state, processing: false }));
        const { i18n } = this.props;
        this.handleCardErrors(
          response,
          i18n.t('Não foi possível fazer o pagamento.')
        );
        this.props.onCreditCardValidated(false);
      },
    });
  }

  getCardMinLength() {
    if (this.state.cardBrand.config) {
      return this.state.cardBrand.config.acceptedLengths[0];
    }
    return '';
  }

  getCardMaxLength() {
    if (this.state.cardBrand.config) {
      const last = this.state.cardBrand.config.acceptedLengths.length - 1;
      return this.state.cardBrand.config.acceptedLengths[last];
    }
    return 19;
  }

  handleCardErrors(response, altErrorMsg = null) {
    const { i18n } = this.props;
    console.log(response);
    if (response.error) {
      if (response.errors) {
        this.setStateIfMounted((state) => ({
          ...state,
          cardValidationErrors: Object.values(response.errors).map((m) =>
            getPagSeguroMessage(m)
          ),
        }));
      } else {
        this.setStateIfMounted((state) => ({
          ...state,
          cardValidationErrors: [altErrorMsg || i18n.t('Erro inesperado')],
        }));
      }
    }
    HtmlUtils.scrollFirstScrollableToTop(this.formRef.current);
    window.scrollTo(0, 0);
  }

  getInstallmentOptions() {
    if (!this.state.installments) {
      return null;
    }

    const { i18n } = this.props;

    return this.state.installments.map((i) => {
      return {
        value: JSON.stringify(i),
        label: `${i.quantity} x ${LocaleUtils.currency(
          parseFloat(i.installmentAmount)
        )} ${i.interestFree ? i18n.t('sem juros') : i18n.t('com juros')}`,
      };
    });
  }

  render() {
    const { i18n } = this.props;
    const cardNumberLabel = (
      <span>
        {i18n.t('Número do cartão')}
        <a
          role="button"
          style={{ marginLeft: '10px' }}
          onClick={() =>
            this.setStateIfMounted((s) => ({ ...s, showModalCards: true }))
          }
        >
          ({i18n.t('cartões aceitos')})
        </a>
      </span>
    );

    const installmentLabel = this.state.form.total_amount
      ? `${i18n.t('Parcelas')} (${i18n.t('total')} ${LocaleUtils.currency(
          parseFloat(this.state.form.total_amount)
        )})`
      : i18n.t('Parcelas');

    let cardAddOn = null;
    if (this.state.cardBrand.name && this.state.form.cardnumber) {
      const urlStatic = 'https://stc.pagseguro.uol.com.br/';
      const cardOption =
        this.state.cardOptions[this.state.cardBrand.name.toUpperCase()];
      if (cardOption) {
        cardAddOn = <img src={`${urlStatic}${cardOption.images.SMALL.path}`} />;
      }
    }

    return (
      <div ref={this.formRef}>
        <AlertError>
          {this.state.cardValidationErrors.length > 0 &&
            this.state.cardValidationErrors.map((e) => <p key={e}>{e}</p>)}
        </AlertError>

        <Row>
          <Col sm={7}>
            <Input
              type="text"
              refFunc={this.cardnumberRef}
              id="id_cardnumber"
              name="cardnumber"
              label={cardNumberLabel}
              value={this.state.form.cardnumber}
              autoComplete="fuck-off"
              required={true}
              error={this.props.errors['cardnumber']}
              minLength={this.getCardMinLength()}
              maxLength={this.getCardMaxLength()}
              onChange={this.onInputChange}
              onBlur={this.fetchBrand}
              addOn={cardAddOn}
            />
          </Col>
          <Col sm={2}>
            <Input
              type="text"
              id="id_validity"
              name="validity"
              label={i18n.t('Validade')}
              help={i18n.t('Mês e ano de validade do cartão')}
              value={this.state.form.validity}
              required={true}
              error={this.props.errors['validity']}
              disabled={
                this.state.cardBrand.config &&
                !this.state.cardBrand.config.hasDueDate
              }
              placeholder="mm/aa"
              mask="99/99"
              onChange={this.onInputChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <Input
              type="text"
              name="cvc"
              label="cvc"
              help={i18n.t('Código de segurança impresso no verso do cartão')}
              value={this.state.form.cvc}
              autoComplete="fuck-off"
              id="id_cvc"
              required={true}
              error={this.props.errors['cvc']}
              disabled={
                this.state.cardBrand.config &&
                !this.state.cardBrand.config.hasCvv
              }
              maxLength={this.state.cardBrand.cvvSize || 5}
              onChange={this.onInputChange}
            />
          </Col>
          <Col sm={5}>
            <Select
              id="id_installment_quantity"
              name="installment_quantity"
              label={installmentLabel}
              disabled={!this.state.installments.length}
              required={true}
              error={this.props.errors['installment_quantity']}
              value={this.state.installment_obj_string}
              onChange={this.onInstallmentChange}
              options={this.getInstallmentOptions()}
            />
          </Col>
        </Row>

        <Modal
          title={i18n.t('Cartões aceitos')}
          show={this.state.showModalCards}
          onCancel={() =>
            this.setStateIfMounted((s) => ({ ...s, showModalCards: false }))
          }
        >
          {Object.keys(this.state.cardOptions).map((key) => {
            const card = this.state.cardOptions[key];
            return (
              <span key={key} className="btn btn-default btn-space">
                <img
                  width="40px"
                  src={`https://stc.pagseguro.uol.com.br/${card.images.SMALL.path}`}
                />
                &nbsp;
                {card.displayName}
                <br />
              </span>
            );
          })}
        </Modal>
      </div>
    );
  }
}

PagSeguroCreditcardForm.propTypes = {
  /**
   * Function that receives the function ref to retrieve the validate card
   * info.
   */
  createTokenFuncRef: PropTypes.func.isRequired,
  /**
   * A function with a boolean parameter which tells if the credit card was
   * validated or not.
   */
  onCreditCardValidated: PropTypes.func.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onProcessing: PropTypes.func,
  onPagSeguroSessionComplete: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  /**
   * The object of errors returned by the backend.
   */
  errors: PropTypes.object,
};

PagSeguroCreditcardForm.defaultProps = {
  errors: {},
};

export default withNamespaces()(PagSeguroCreditcardForm);
