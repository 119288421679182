import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../common/ajax';
import Config from '../../config';
import GetSiteControl from '../../integrations/GetSiteControl';
import { getUser } from '../../context/global';
import ErrorBoundary from '../../common/containers/ErrorBoundary';
import DiscountField from './DiscountField';
import { Link } from 'react-router-dom';
import URLS from '../../urls';
import MercadoPagoStatusV2 from './MercadoPagoStatusV2';
import ModalAlert from '../../common/components/ModalAlert';

function MercadoPagoPayment({ plan, i18n }) {
  const [checkout, setCheckout] = useState(null);
  const [discount, setDiscount] = useState({ code: null, price: null });
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [preference, setPreference] = useState(null);
  const [publicKey, setPublicKey] = useState(null);

  useEffect(() => {
    if (!document.getElementById('sdkmp')) {
      const script = document.createElement('script');
      script.id = 'sdkmp';
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.onload = () => fetchConfiguration();
      document.body.appendChild(script);
    } else {
      fetchConfiguration();
    }

    // Cleanup on unmount.
    return () => {
      if (window.paymentBrickController) {
        window.paymentBrickController.unmount();
      }
    };
  }, []);

  useEffect(() => {
    if (preference) {
      const mp = new MercadoPago(publicKey);
      const bricksBuilder = mp.bricks();
      renderPaymentBrick(bricksBuilder);
    }
  }, [preference]);

  useEffect(() => {
    if (discount.code) {
      showLoading();
      paymentBrickController.unmount();
      createPreference();
    }
  }, [discount.code]);

  async function fetchConfiguration() {
    const url = `${Config.apiHost}checkouts_mercadopago/config/`;
    Ajax.get(url).then((configuration) => {
      setPublicKey(configuration.public_key);
      createPreference();
    });
  }

  async function createPreference() {
    Ajax.post(`${Config.apiHost}checkouts_mercadopago/create_preference/`, {
      plan_id: plan.id,
      discount_code: discount.code,
    })
      .done((preference) => setPreference(preference))
      .fail((jqXHR) => {
        setValidationErrors(jqXHR.responseJSON);
        setErrorMessage(i18n.t('Não foi possível iniciar o mecanismo de pagamento. Tente novamente.'));
      });
  }

  async function renderPaymentBrick(bricksBuilder) {
    const settings = {
      initialization: {
        amount: preference.items.reduce((partialSum, a) => partialSum + a.unit_price, 0),
        preferenceId: preference.id,
        payer: { email: Config.isProduction ? getUser().email : null },
      },
      customization: {
        visual: { hideFormTitle: true },
        paymentMethods: {
          atm: 'all',
          ticket: 'all',
          bankTransfer: 'all',
          debitCard: 'all',
          // mercadoPago: 'all',
          creditCard: 'all',
        },
      },
      callbacks: {
        onReady: hideLoading,
        onSubmit: submitCheckout,
        onError: (error) => {
          setErrorMessage(i18n.t('Não foi possível carregar o mecanismo de pagamento. Tente novamente.'));
          console.error(error);
        },
      },
    };
    window.paymentBrickController = await bricksBuilder.create('payment', 'paymentBrick_container', settings);
  }

  function submitCheckout({ paymentMethod, formData }) {
    // TODO: pagar com mercado pago.
    // if (!paymentMethod || !payment_data) {
    //   return;
    // }

    const data = { payment_data: formData, plan_id: plan.id, discount_code: discount.code };
    return new Promise((resolve, reject) => {
      Ajax.post(`${Config.apiHost}checkouts_mercadopago/create_checkout/`, data)
        .done((response) => {
          console.log(response);
          setCheckout(response);
          setErrorMessage(null);
          resolve();
        })
        .fail((jqXHR) => {
          console.log(jqXHR);
          setValidationErrors(jqXHR.responseJSON);
          setErrorMessage(i18n.t('Não foi possível processar o pagamento. Tente novamente.'));
          reject();
        });
    });
  }

  function hideLoading() {
    const el = document.getElementById('loading-div');
    if (el) {
      el.classList.remove('be-loading-active');
    }
  }

  function showLoading() {
    const el = document.getElementById('loading-div');
    if (el) {
      el.classList.add('be-loading-active');
    }
  }

  if (checkout) {
    try {
      GetSiteControl.show(399206);
    } catch (error) {}
  }

  return (
    <>
      {errorMessage && (
        <ModalAlert
          show={true}
          type="danger"
          onClose={() => {
            setErrorMessage(null);
            setValidationErrors({});
          }}
        >
          <h4>{errorMessage}</h4>
          {validationErrors && (
            <h4>
              <i>{validationErrors.detail}</i>
            </h4>
          )}
        </ModalAlert>
      )}

      {checkout && (
        <>
          <h4>
            {i18n.t('Você também pode consultar a situação dos seus pagamento em')}{' '}
            <Link to={URLS.billing}>{i18n.t('Meus pagamentos e vouchers')}</Link>.
          </h4>
          <MercadoPagoStatusV2 checkout={checkout} publicKey={publicKey} />
        </>
      )}

      {!checkout && (
        <>
          <div id="loading-div" className="be-loading be-loading-active" style={{ minHeight: '200px' }}>
            {/* Needs to use the pure loading div because MercadoPago React does not handle react state changes very well. */}
            <div className="be-spinner">
              <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30" className="circle" />
              </svg>
            </div>
            <ErrorBoundary featureName={i18n.t('Desconto')}>
              <DiscountField
                plan={plan}
                onApply={(code, price) => {
                  setDiscount({ code, price });
                }}
                onProcessing={(isProcessing) => (isProcessing ? showLoading() : hideLoading())}
                errors={validationErrors}
              />
            </ErrorBoundary>
            <div id="paymentBrick_container" />
          </div>
        </>
      )}
    </>
  );
}

export default withNamespaces()(MercadoPagoPayment);
