import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from "./Input";
import Select from "./Select";
import Row from "../../containers/Row";
import Col from "../../containers/Col";
import {withNamespaces} from "react-i18next";
import Ajax from "../../ajax";

class AddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            form: {
                postal_code: '',
                street: '',
                number: '',
                complement: '',
                district: '',
                city: '',
                state: '',
            },
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.fillAddressByCEP = this.fillAddressByCEP.bind(this);
        this.ufList = [
            'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO',
            'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR',
            'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'
        ];
        this.numberRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.onProcessing &&
            prevState.processing !== this.state.processing) {
            this.props.onProcessing(this.state.processing);
        }

        if (!this.state.form.number && this.state.form.street) {
            this.numberRef.current.focus();
        }
    }

    onInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                [name]: value
            }
        }), () => {
            if (this.props.onChange) {
                this.props.onChange({...this.state.form});
            }

            if (name === 'postal_code') {
                this.fillAddressByCEP();
            }
        });
    }

    fillAddressByCEP() {
        if (this.state.form.postal_code.length !== 8) {
            return;
        }
        this.setState(state => ({...state, processing: true}));
        const url = `https://viacep.com.br/ws/${this.state.form.postal_code}/json/`;
        Ajax.getWithoutCredentials(url).done(data => {
            this.setState(state => ({
                ...state,
                processing: false,
                form: {
                    ...state.form,
                    district: data.bairro,
                    street: data.logradouro,
                    city: data.localidade,
                    state: data.uf,
                }
            }));
        }).fail(() => {
            this.setState(state => ({...state, processing: false}));
        });
    }

    render() {
        const {i18n, title} = this.props;

        return (
            <React.Fragment>
                {title && (
                    <p>
                        <b>{title}</b>
                    </p>
                )}

                <Row>
                    <Col sm={3}>
                        <Input
                            id="id_postal_code"
                            type="text"
                            name="postal_code"
                            value={this.state.form.postal_code}
                            label="CEP"
                            autoComplete="fuck-off"
                            maxLength="8"
                            required={true}
                            error={this.props.errors && this.props.errors['postal_code']}
                            onChange={this.onInputChange}
                            data-lpignore="true"
                        />
                    </Col>
                    <Col sm={9}>
                        <Input
                            id="id_street"
                            type="text"
                            name="street"
                            value={this.state.form.street}
                            label={i18n.t('Rua')}
                            autoComplete="fuck-off"
                            maxLength="500"
                            required={true}
                            error={this.props.errors && this.props.errors['street']}
                            onChange={this.onInputChange}
                            data-lpignore="true"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={3}>
                        <Input
                            id="id_number"
                            refFunc={this.numberRef}
                            type="text"
                            name="number"
                            label={i18n.t('Número')}
                            value={this.state.form.number}
                            autoComplete="fuck-off"
                            maxLength="500"
                            required={true}
                            error={this.props.errors && this.props.errors['number']}
                            onChange={this.onInputChange}
                            data-lpignore="true"
                        />
                    </Col>
                    <Col sm={9}>
                        <Input
                            id="id_complement"
                            type="text"
                            name="complement"
                            value={this.state.form.complement}
                            label={i18n.t('Complemento')}
                            autoComplete="fuck-off"
                            maxLength="40"
                            error={this.props.errors && this.props.errors['complement']}
                            onChange={this.onInputChange}
                            data-lpignore="true"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={5}>
                        <Input
                            id="id_district"
                            type="text"
                            name="district"
                            value={this.state.form.district}
                            label={i18n.t('Bairro')}
                            autoComplete="fuck-off"
                            maxLength="500"
                            required={true}
                            error={this.props.errors && this.props.errors['district']}
                            onChange={this.onInputChange}
                            data-lpignore="true"
                        />
                    </Col>
                    <Col sm={4}>
                        <Input
                            id="id_city"
                            type="text"
                            name="city"
                            value={this.state.form.city}
                            label={i18n.t('Cidade')}
                            autoComplete="fuck-off"
                            maxLength="500"
                            required={true}
                            error={this.props.errors && this.props.errors['city']}
                            onChange={this.onInputChange}
                            data-lpignore="true"
                        />
                    </Col>
                    <Col sm={3}>
                        <Select
                            id="id_state"
                            name="state"
                            value={this.state.form.state}
                            label={i18n.t('Estado')}
                            required={true}
                            error={this.props.errors && this.props.errors['state']}
                            onChange={this.onInputChange}
                            options={this.ufList.map(uf => ({value: uf, label: uf}))}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

AddressForm.propTypes = {
    /**
     * THe title of the address form.
     */
    title: PropTypes.string,
    /**
     * A function that receives the address object, containing the keys
     * postal_code, district, street, number, complement, city and state.
     */
    onChange: PropTypes.func.isRequired,
    /**
     * A function that receives true/false indicating that the address form is
     * (not) being processed.
     */
    onProcessing: PropTypes.func,
    /**
     * The object of errors returned by the backend.
     */
    errors: PropTypes.object,
};

export default withNamespaces()(AddressForm);
