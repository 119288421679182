import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Col from '../../common/containers/Col';
import Button from '../../common/components/Button';
import { Link } from 'react-router-dom';
import URLS from '../../urls';
import pagseguroImage from '../pagseguro.jpg';
import Row from '../../common/containers/Row';
import { withNamespaces } from 'react-i18next';

class ConfirmCancel extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <Row>
        <Col md={12}>
          <Button
            type="primary"
            size="lg"
            submit={true}
            processing={this.props.processing}
          >
            {i18n.t('Confirmar')}
          </Button>
          &nbsp;
          <Link to={URLS.plans} className="btn btn-default btn-lg">
            {i18n.t('Cancelar')}
          </Link>
          <div className="pull-right" style={{ marginLeft: '10px' }}>
            <small className="text-muted">
              <i className="fas fa-lock" />
              &nbsp;
              {i18n.t('Processado com segurança por')}
            </small>
            <br />
            <img
              style={{ marginTop: '-5px' }}
              src={pagseguroImage}
              width="80px"
              alt="PAGSEGURO INTERNET S/A"
              title="PAGSEGURO INTERNET S/A"
            />
          </div>
        </Col>
      </Row>
    );
  }
}

ConfirmCancel.propTypes = {
  processing: PropTypes.bool,
};

export default withNamespaces()(ConfirmCancel);
