import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import Ajax from '../../common/ajax';
import Col from '../../common/containers/Col';
import Content from '../../common/containers/Content';
import ErrorBoundary from '../../common/containers/ErrorBoundary';
import Panel from '../../common/containers/Panel';
import PrivatePage from '../../common/containers/PrivatePage';
import Row from '../../common/containers/Row';
import Config from '../../config';
import URLS from '../../urls';
import PayWithBankSlip from './PayWithBankSlip';
import PayWithCreditCard from './PayWithCreditCard';
import PayPalButton from './PayPalButton';
import PayWithMercadoPago from './PayWithMercadoPagoV2';
import mercadopagoIcon from './mercado-pago-sign.svg';

class PayPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabId: 'mercadopago', // creditcard, bankslip or paypal
      plan: null,
      processing: 0,
      configuration: null,
      showAllPaymentMethods: false,
    };
    this.onProcessing = this.onProcessing.bind(this);
  }

  componentDidMount() {
    this.fetchPlan();
    this.fetchConfig();
  }

  fetchPlan() {
    this.setState((state) => ({ ...state, processing: state.processing + 1 }));
    const planId = this.props.match.params.planId;
    const amount = this.props.location.amount || 0;
    const url = `${Config.apiHost}plans/${planId}/?amount=${amount}`;
    Ajax.get(url)
      .done((plan) => {
        this.setState((state) => ({ ...state, plan }));
      })
      .always(() => {
        this.setState((state) => ({
          ...state,
          processing: state.processing - 1,
        }));
      });
  }

  fetchConfig() {
    this.setState((state) => ({ ...state, processing: state.processing + 1 }));
    const url = `${Config.apiHost}checkouts/config/`;
    Ajax.get(url)
      .done((configuration) => {
        const data = { configuration };
        if (configuration.is_out_of_brazil) {
          data.tabId = 'paypal';
        }
        this.setState((state) => ({
          ...state,
          ...data,
        }));
      })
      .always(() => {
        this.setState((state) => ({
          ...state,
          processing: state.processing - 1,
        }));
      });
  }

  onProcessing(isProcessing) {
    if (isProcessing) {
      this.setState((state) => ({
        ...state,
        processing: state.processing + 1,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        processing: state.processing - 1,
      }));
    }
  }

  getPaymentOptions() {
    const { i18n } = this.props;

    const creditcard = {
      id: 'creditcard',
      icon: 'icon mdi mdi-card',
      label: <span>{i18n.t('Cartão de crédito')} (via PagSeguro)</span>,
      content: (
        <PayWithCreditCard
          plan={this.state.plan}
          onProcessing={this.onProcessing}
          amount={this.props.location.amount}
        />
      ),
    };

    const bankslip = {
      id: 'bankslip',
      icon: 'fas fa-barcode',
      label: <span>{i18n.t('Boleto')} (via PagSeguro)</span>,
      content: (
        <PayWithBankSlip plan={this.state.plan} onProcessing={this.onProcessing} amount={this.props.location.amount} />
      ),
    };

    const mercadopago = {
      id: 'mercadopago',
      iconImg: <img src={mercadopagoIcon} width="30px" />,
      label: 'MercadoPago',
      content: (
        <PayWithMercadoPago
          plan={this.state.plan}
          onProcessing={this.onProcessing}
          amount={this.props.location.amount}
        />
      ),
    };

    const paypal = {
      id: 'paypal',
      icon: 'fab fa-paypal',
      label: 'PayPal',
      content: <PayPalButton plan={this.state.plan} onProcessing={this.onProcessing} />,
    };

    let tabs = [mercadopago, creditcard, bankslip, paypal];

    return tabs;
  }

  render() {
    const { i18n } = this.props;

    const { processing, tabId, configuration, plan, showAllPaymentMethods } = this.state;

    const paymentOptions = (configuration && this.getPaymentOptions()) || [];

    let selectedPaymentOption = null;

    if (plan && tabId) {
      selectedPaymentOption = paymentOptions.find((i) => i.id === tabId);
    }

    return (
      <PrivatePage
        title={i18n.t('Pagamento')}
        hasAside={false}
        leftSidebarCollapsed={true}
        isProcessing={processing > 0}
      >
        <Content>
          <ol className="breadcrumb">
            <li>
              <Link to={URLS.plans}>{i18n.t('Preços')}</Link>
            </li>
            {plan && <li>{plan.name}</li>}
            <li>{i18n.t('Pagamento')}</li>
          </ol>
          <Row>
            <Col md={8} mdOffset={2}>
              <Panel isProcessing={processing > 0}>
                {!showAllPaymentMethods && plan && (
                  <>
                    <PayWithMercadoPago
                      plan={plan}
                      onProcessing={this.onProcessing}
                      amount={this.props.location.amount}
                    />
                    <div className="text-muted">
                      <img src={mercadopagoIcon} width="30px" /> {i18n.t('Pagamentos processados com segurança pelo')}{' '}
                      <b>MercadoPago</b>.
                    </div>
                  </>
                )}

                {showAllPaymentMethods && plan && (
                  <>
                    <ul className="nav nav-tabs" role="tablist">
                      {paymentOptions.map((option) => {
                        return (
                          <li key={option.id} role="presentation" className={tabId === option.id ? 'active' : ''}>
                            <a
                              aria-controls={option.id}
                              role="button"
                              onClick={() =>
                                this.setState((state) => ({
                                  ...state,
                                  tabId: option.id,
                                }))
                              }
                            >
                              {(option.icon && (
                                <>
                                  <i className={option.icon} /> {option.label}
                                </>
                              )) || (
                                <>
                                  {option.iconImg} {option.label}
                                </>
                              )}
                            </a>
                          </li>
                        );
                      })}
                    </ul>

                    <div className="tab-content">
                      <div role="tabpanel" className="tab-pane active">
                        {selectedPaymentOption && selectedPaymentOption.content && (
                          <ErrorBoundary>{selectedPaymentOption.content}</ErrorBoundary>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Panel>

              {!showAllPaymentMethods && (
                <div className="text-muted">
                  {i18n.t('Se estiver fora da América Latina ou com problemas no pagamento, tente')}{' '}
                  <a
                    role="button"
                    onClick={() =>
                      this.setState((state) => ({
                        ...state,
                        showAllPaymentMethods: true,
                        tabId: state.configuration.is_out_of_brazil ? 'paypal' : 'creditcard',
                      }))
                    }
                  >
                    {i18n.t('outros meios de pagamento')}
                  </a>
                  .
                </div>
              )}
            </Col>
          </Row>
        </Content>
      </PrivatePage>
    );
  }
}

PayPage.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withNamespaces()(PayPage);
