
const PagSeguroMessages = {
    "Credit Card Owner's Name": "Nome do Dono do Cartão",
    "Installments": "Parcelas",
    "items invalid quantity": "Quantidade inválida de itens",
    "currency is required": "Moeda é obrigatório",
    "Credit Card Owner's CPF": "CPF do Dono do Cartão",
    "Credit Card Number": "Número do Cartão",
    "Your CPF": "Seu CPF",
    "Send new order email": "Enviar e-mail de novo pedido",
    "invalid creditcard brand": "Bandeira do cartão inválida",
    "creditcard number with invalid length": "Cartão de crédito com tamanho inválido",
    "invalid date format": "Data de validade com formato inválido",
    "invalid security field": "Campo de segurança inválido",
    "cvv is mandatory": "CVV é obrigatório",
    "security field with invalid length": "Código de segurança com tamanho incorreto",
    "currency invalid value": "Moeda inválida",
    "reference invalid length": "Referência com tamanho inválido",
    "notificationURL invalid length": "Tamanho inválido do notificationURL",
    "notificationURL invalid value": "Valor inválido do notificationURL",
    "sender email is required": "Email do comprador é obrigatório",
    "sender email invalid length": "Tamanho inválido do email do comprador",
    "sender email invalid value": "Email do comprador incorreto",
    "sender name is required": "Nome do comprador é obrigatório",
    "sender name invalid length": "Nome do comprador com tamanho inválido",
    "sender name invalid value": "Nome do comprador com valor inválido",
    "sender cpf invalid value": "CPF do comprador inválido",
    "sender area code is required": "Código de área do comprador é obrigatório",
    "sender area code invalid value": "Código de área do comprador inválido",
    "sender phone is required": "Telefone do comprador é obrigatório",
    "sender phone invalid value": "Telefone do comprador inválido",
    "shipping address postal code is required": "CEP de entrega é obrigatório",
    "shipping address postal code invalid value": "CEP de entrega inválido",
    "shipping address street is required": "Endereço de entrega é obrigatório",
    "shipping address street invalid length": "Endereço de entrega com tamanho inválido",
    "shipping address number is required": "Número do endereço de entrega é obrigatório",
    "shipping address number invalid length": "Número do endereço de entrega com tamanho inválido",
    "shipping address complement invalid length": "Complemento do endereço de entrega com tamanho inválido",
    "shipping address district is required": "Bairro de entrega é obrigatório",
    "shipping address district invalid length": "Bairro de entrega com tamanho inválido",
    "shipping address city is required": "Cidade de entrega é obrigatório",
    "shipping address city invalid length": "Cidade de entrega com tamanho inválido",
    "shipping address state is required": "Estado de entrega é obrigatório",
    "shipping address state invalid value": "Estado de entrega com valor inválido",
    "shipping address country is required": "País de entrega é obrigatório",
    "shipping address country invalid length": "País de entrega com tamanho inválido",
    "credit card token is required": "Token do cartão é obrigatório",
    "installment quantity is required": "Quantidade de parcelas é obrigatório",
    "installment quantity invalid value": "Quantidade de parcelas incorreto",
    "installment value is required": "Valor da parcela é obrigatório",
    "installment value invalid value": "Valor da parcela inválido",
    "credit card holder name is required": "Nome do titular do cartão é obrigatório",
    "credit card holder name invalid length": "Nome do titular do cartão com tamanho inválido",
    "credit card holder name invalid value": "Nome do titular do cartão incorreto",
    "credit card holder cpf is required": "CPF do titular do cartão é obrigatório",
    "credit card holder cpf invalid value": "CPF do titular do cartão inválido",
    "credit card holder birthdate is required": "Data de nascimento do titular do cartão é obrigatório",
    "credit card holder birthdate invalid value": "Data de nascimento do titular do cartão incorreto",
    "credit card holder area code is required": "Código de área do titular do cartão é obrigatório",
    "credit card holder area code invalid value": "Código de área do titular do cartão inválido",
    "credit card holder phone is required": "Telefone do titular do cartão é obrigatório",
    "credit card holder phone invalid value": "Telefone do titular do cartão inválido",
    "billing address postal code is required": "CEP de cobrança é obrigatório",
    "billing address postal code invalid value": "CEP de cobrança inválido",
    "billing address street is required": "Endereço de cobrança é obrigatório",
    "billing address street invalid length": "Endereço de cobrança com tamanho inválido",
    "billing address number is required": "Número do endereço de cobrança é obrigatório",
    "billing address number invalid length": "Número do endereço de cobrança com tamanho inválido",
    "billing address complement invalid length": "Complemento do endereço de cobrança com tamanho inválido",
    "billing address district is required": "Bairro do endereço de cobrança é obrigatório",
    "billing address district invalid length": "Bairro do endereço de cobrança com tamanho inválido",
    "billing address city is required": "Cidade do endereço de cobrança é obrigatório",
    "billing address city invalid length": "Cidade do endereço de cobrança com tamanho inválido",
    "billing address state is required": "Estado do endereço de cobrança é obrigatório",
    "billing address state invalid value": "Estado do endereço de cobrança inválido",
    "billing address country is required": "País do endereço de cobrança é obrigatório",
    "billing address country invalid length": "País do endereço de cobrança com tamanho inválido",
    "receiver email invalid length": "E-mail do vendedor com tamanho inválido",
    "receiver email invalid value": "E-mail do vendedor inválido",
    "item id is required": "Id do item é obrigatório",
    "item id invalid length": "Id do item possuí tamanho inválido",
    "item description is required": "Descrição do item é obrigatório",
    "item description invalid length": "Descrição do item com tamanho inválido",
    "item quantity is required": "Quantidade do item é obrigatório",
    "item quantity out of range": "Quantidade do item fora do permitido",
    "item quantity invalid value": "Quantidade do item inválida",
    "item amount is required": "Valor do item é obrigatório",
    "item amount invalid pattern. Must fit the patern: \\d+.\\d\{2\}": "Valor do item deve seguir o padrão: \\d+.\\d\{2\}",
    "item amount out of range": "Valor do item fora do permitido",
    "sender is related to receiver": "O comprador está relacionado ao vendedor",
    "invalid receiver, verify receiver's account status and if it is a seller's account": "Vendedor inválido, verifique se a conta do lojista é uma conta de vendedor",
    "payment method unavailable": "Método de pagamento não disponível",
    "cart total amount out of range": "Valor total do carrinho fora do permitido",
    "invalid creditcard data": "Dados de cartão inválidos",
    "invalid credit card data": "Dados de cartão inválidos",
    "sender hash invalid": "Hash de comprador inválido",
    "credit card brand is not accepted": "Bandeira de cartão não aceita",
    "shipping type invalid pattern": "Tipo de frete for do padrão",
    "shipping cost invalid pattern": "Custo de frete fora dos padrões",
    "shipping cost out of range": "Custo de frete fora dos limites",
    "cart total value is negative": "Valor total do carrinho é negativo",
    "extra amount invalid pattern. Must fit the patern: -?\\d+.\\d\{2\}": "Valor extra incompatível. Deve seguir o padrão: -?\\d+.\\d{2\}",
    "payment mode invalid value, valid values are default and gateway": "Modo de pagamento inválido. Valores válidos são: default e gateway",
    "payment method invalid value, valid values are creditCard, boleto e eft": "Método de pagamento inválido. Valores aceitos são: creditCard, boleto e eft",
    "shipping cost was provided, shipping address must be complete": "Valor de frete foi especificado, endereço de entrega deve estar completo",
    "sender information was provided, email must be provided too": "Dados do comprador foram especificados, email deve ser especificado também",
    "credit card holder is incomplete": "Nome do titular do cartão está incompleto",
    "shipping address information was provided, sender email must be provided too": "Endereço de entrega foi fornecido, e-mail do comprador também deve ser fornecido",
    "eft bank is required": "Banco para tef é obrigatório",
    "eft bank is not accepted": "Banco não aceito para tef",
    "sender born date invalid value": "Data de nascimento do comprador inválida",
    "sender email invalid domain. You must use an email @sandbox.pagseguro.com.br": "Domínio do e-mail do comprador inválido. Você deve usar um e-mail @sandbox.pagseguro.com.br",
    "installment quantity out of range. The value must be greater than zero": "Quantidade de parcelas fora do limite. O valor deve ser maior que zero",
    "sender is blocked": "Comprador está bloqueado pelo PagSeguro",
    "credit card token invalid": "Token de cartão de crédito inválido."
};

export function getPagSeguroMessage(messageKey) {
    const token = ': ';
    if (messageKey.indexOf(token) > -1) {
        const parts = messageKey.split(token);
        return (PagSeguroMessages[parts[0]] || parts[0]) + ': ' + parts[1];
    }
    return PagSeguroMessages[messageKey] || messageKey;
}