import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Input from '../../common/components/form/Input';
import Col from '../../common/containers/Col';
import Row from '../../common/containers/Row';

class PagSeguroIdentificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      pagseguroErrors: [],
      form: {
        name: '',
        br_cpf: '',
        birthday: '',
        phone_number: '',
      },
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    this.props.onChange({ ...this.state.form });
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      (state) => ({
        ...state,
        form: {
          ...state.form,
          [name]: value,
        },
      }),
      () => {
        this.props.onChange({ ...this.state.form });
      }
    );
  }

  render() {
    const { i18n } = this.props;
    const name = this.props.isForCreditCard
      ? i18n.t('Nome impresso no cartão')
      : i18n.t('Nome completo');
    return (
      <React.Fragment>
        <Row>
          <Col sm={6}>
            <Input
              type="text"
              name="name"
              label={name}
              help={name}
              value={this.state.form.name}
              autoComplete="fuck-off"
              id="id_name"
              required={true}
              error={this.props.errors['name']}
              maxLength="50"
              onChange={this.onInputChange}
            />
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              name="br_cpf"
              label="CPF"
              value={this.state.form.br_cpf}
              autoComplete="fuck-off"
              required={true}
              error={this.props.errors['br_cpf']}
              mask="999.999.999-99"
              id="id_br_cpf"
              onChange={this.onInputChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Input
              type="text"
              name="birthday"
              label={i18n.t('Data de nascimento')}
              value={this.state.form.birthday}
              autoComplete="fuck-off"
              required={true}
              error={this.props.errors['birthday']}
              mask="99/99/9999"
              id="id_birthday"
              onChange={this.onInputChange}
            />
          </Col>
          <Col sm={4}>
            <Input
              type="text"
              name="phone_number"
              label={i18n.t('Telefone')}
              value={this.state.form.phone_number}
              autoComplete="fuck-off"
              required={true}
              error={this.props.errors['phone_number']}
              mask="(99) 99999-9999"
              id="id_phone_number"
              onChange={this.onInputChange}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

PagSeguroIdentificationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  /**
   * The object of errors returned by the backend.
   */
  errors: PropTypes.object,
  isForCreditCard: PropTypes.bool,
};

PagSeguroIdentificationForm.defaultProps = {
  errors: {},
};

export default withNamespaces()(PagSeguroIdentificationForm);
