import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../common/ajax';
import ModalAlert from '../../common/components/ModalAlert';
import ErrorBoundary from '../../common/containers/ErrorBoundary';
import Modal from '../../common/containers/Modal';
import Waiting from '../../common/containers/Waiting';
import Config from '../../config';
import { AppContext, isLoggedIn } from '../../context/global';
import URLS from '../../urls';
import DiscountField from './DiscountField';

const STATUS = {
  capturing: 0,
  success: 1,
  error: 2,
};

class PayPalButton extends Component {
  constructor(props) {
    super(props);
    this.createOrder = this.createOrder.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onError = this.onError.bind(this);
    this.onInit = this.onInit.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onDiscountApplied = this.onDiscountApplied.bind(this);
    this.state = {
      isInitiated: false,
      status: null,
      isSdkReady: false,
      usePayPal: false,
      loadingError: false,
      discountedPrice: null,
      discountCode: null,
      configuration: {},
    };
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchConfiguration();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setStateIfMounted(state, callback) {
    if (this._mounted) {
      this.setState(state, callback);
    }
  }

  fetchConfiguration() {
    const url = `${Config.apiHost}checkouts/config/`;
    Ajax.get(url).then((configuration) => {
      if (configuration.use_paypal && !this.state.isSdkReady) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.paypal.com/sdk/js?client-id=${configuration.client_id}&currency=${configuration.currency_code}`;
        script.async = true;
        script.onload = () => {
          this.setStateIfMounted((state) => ({
            ...state,
            isSdkReady: true,
          }));
        };
        script.onerror = () => {
          this.setStateIfMounted((state) => ({
            ...state,
            loadingError: true,
          }));
          throw new Error('Paypal SDK could not be loaded.');
        };
        document.body.appendChild(script);
      }
      this.setStateIfMounted((state) => ({ ...state, configuration }));
    });
  }

  onDiscountApplied(discountCode, discountedPrice) {
    this.setStateIfMounted((state) => ({
      ...state,
      discountedPrice,
      discountCode,
    }));
  }

  onInit(data, actions) {
    this.setStateIfMounted((state) => ({ ...state, isInitiated: true }));
  }

  onClick(data, actions) {
    let valid = true;
    if (isLoggedIn()) {
      const { id } = this.props.plan;
      Ajax.getSync(`${Config.apiHost}plans/${id}`).fail(() => {
        valid = false;
      });
    } else {
      valid = false;
      setTimeout(() => {
        const next = window.location.pathname;
        window.location = `${Config.djangoHost}accounts/login?next=${next}`;
      }, 0);
    }

    if (valid) {
      return actions.resolve();
    } else {
      this.setStateIfMounted((state) => ({ ...state, status: STATUS.error }));
      return actions.reject();
    }
  }

  onError() {
    this.setStateIfMounted((state) => ({ ...state, status: STATUS.error }));
  }

  getDescription() {
    let description = `Compra Plano ${this.props.plan.name}.`;
    if (this.state.discountCode) {
      description += ` Cupom de desconto ${this.state.discountCode}.`;
    }
    return description;
  }

  createOrder(data, actions) {
    const { plan } = this.props;
    let promise;

    Ajax.postSync(`${Config.apiHost}checkouts/create_paypal/`, {
      plan_id: plan.id,
      discount_code: this.state.discountCode,
    })
      .done((checkout) => {
        promise = actions.order.create({
          purchase_units: [
            {
              amount: {
                value: checkout.total,
              },
              custom_id: checkout.id,
              description: this.getDescription(),
              soft_descriptor: `FastFormat-${plan.name}`,
            },
          ],
        });

        promise.then((orderID) => {
          Ajax.post(`${Config.apiHost}checkouts/update_paypal/`, {
            order_id: orderID,
            checkout_id: checkout.id,
          });
          return orderID;
        });
      })
      .fail(() => {
        this.setStateIfMounted((state) => ({ ...state, status: STATUS.error }));
      });

    return promise;
  }

  onApprove(data, actions) {
    this.setStateIfMounted((state) => ({ ...state, status: STATUS.capturing }));
    let promise = actions.order.capture().then((details) => {
      const approveData = {
        order_id: data.orderID,
        transaction_id: details.purchase_units[0].payments.captures[0].id,
      };
      Ajax.post(`${Config.apiHost}checkouts/approve_paypal/`, approveData)
        .done(() => {
          this.setStateIfMounted((state) => ({
            ...state,
            status: STATUS.success,
          }));
        })
        .fail(() => {
          this.setStateIfMounted((state) => ({
            ...state,
            status: STATUS.error,
          }));
        });
    });
    return promise;
  }

  render() {
    if (!this.state.isSdkReady) {
      return <Waiting isProcessing={true} />;
    }

    const { i18n } = this.props;
    const PayPalButtonDriver = paypal.Buttons.driver('react', {
      React,
      ReactDOM,
    });

    return (
      <Waiting isProcessing={!this.state.isInitiated}>
        <ErrorBoundary featureName={i18n.t('Desconto')}>
          <DiscountField
            plan={this.props.plan}
            onApply={this.onDiscountApplied}
            onProcessing={this.props.onProcessing}
            currencyLabel={this.state.configuration.currency_label}
          />
        </ErrorBoundary>

        <br />

        {!this.state.isInitiated && (
          <h3>
            {i18n.t('Carregando')} PayPal... {i18n.t('Aguarde')}.
          </h3>
        )}

        <div
          style={{
            maxWidth: '250px',
            display: this.state.isInitiated ? 'block' : 'none',
          }}
        >
          <PayPalButtonDriver
            onInit={this.onInit}
            onClick={this.onClick}
            createOrder={this.createOrder}
            onApprove={this.onApprove}
            onCancel={() =>
              this.setStateIfMounted((state) => ({ ...state, status: null }))
            }
            onError={this.onError}
            style={{
              layout: 'horizontal',
              label: 'pay',
              color: 'blue',
              tagline: false,
              height: 38,
            }}
          />
        </div>

        {this.state.status === STATUS.capturing && (
          <Modal
            show={true}
            isProcessing={true}
            title={i18n.t('Processando transação...')}
          />
        )}

        {this.state.status === STATUS.success && (
          <ModalAlert show={true} type="success">
            <h3>{i18n.t('Transação realizada com sucesso.')}</h3>
            <h3>{i18n.t('Obrigado pela compra!')}</h3>
            <br />
            <a href={URLS.documents} className="btn btn-primary">
              {i18n.t('Ir para meus documentos')}
            </a>
          </ModalAlert>
        )}

        {this.state.status === STATUS.error && (
          <ModalAlert
            show={true}
            onClose={() =>
              this.setStateIfMounted((state) => ({ ...state, status: null }))
            }
            type="danger"
          >
            <h3>
              {i18n.t(
                'Não foi possível realizar a transação. Tente novamente.'
              )}
            </h3>
          </ModalAlert>
        )}
      </Waiting>
    );
  }
}

PayPalButton.propTypes = {
  plan: PropTypes.object.isRequired,
  onProcessing: PropTypes.func.isRequired,
};

PayPalButton.contextType = AppContext;

export default withNamespaces()(PayPalButton);
