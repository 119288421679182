import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Ajax from '../../common/ajax';
import { AlertError } from '../../common/components/Alert';
import AddressForm from '../../common/components/form/AddressForm';
import ErrorBoundary from '../../common/containers/ErrorBoundary';
import Waiting from '../../common/containers/Waiting';
import HtmlUtils from '../../common/htmlutils';
import Config from '../../config';
import GetSiteControl from '../../integrations/GetSiteControl';
import URLS from '../../urls';
import ConfirmCancel from './ConfirmCancel';
import DiscountField from './DiscountField';
import PagSeguroIdentificationForm from './PagSeguroIdentificationForm';
import { getPagSeguroMessage } from './PagSeguroMessages';

class PayWithBankSlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      checkout: null,
      genericError: null,
      pagseguroErrors: null,
      validationErrors: {},
      discountedPrice: '',

      loadingPagseguro: false,
      pagseguroIsLoaded: false,
      pagseguroScriptError: false,
    };
    this.form = {
      template_price: this.props.plan.id,
    };
    this.setPagseguroOK = this.setPagseguroOK.bind(this);
    this.setPagseguroNotOK = this.setPagseguroNotOK.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onProcessing = this.onProcessing.bind(this);
    this.submit = this.submit.bind(this);
    this.handleBackendErrors = this.handleBackendErrors.bind(this);
    this.onDiscountApplied = this.onDiscountApplied.bind(this);
    this.nodeRef = React.createRef();
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.loadPagSeguroScript();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadPagSeguroScript() {
    if (!window.PagSeguroDirectPayment) {
      this.setState((state) => ({ ...state, loadingPagseguro: true }));
      let host = Config.isProduction
        ? 'https://stc.pagseguro.uol.com.br'
        : 'https://stc.sandbox.pagseguro.uol.com.br';
      const url = `${host}/pagseguro/api/v2/checkout/pagseguro.directpayment.js`;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      script.onload = () => {
        this.setPagseguroOK();
      };
      script.onerror = () => {
        this.setPagseguroNotOK();
      };
      document.body.appendChild(script);
    } else {
      this.setPagseguroOK();
    }
  }

  setPagseguroOK() {
    this.setState((state) => ({
      ...state,
      pagseguroScriptError: false,
      pagseguroIsLoaded: true,
      loadingPagseguro: false,
    }));
    this.onProcessing(false);
  }

  setPagseguroNotOK() {
    this.setState((state) => ({
      ...state,
      pagseguroScriptError: true,
      pagseguroIsLoaded: false,
      loadingPagseguro: false,
    }));
    this.onProcessing(false);
  }

  onProcessing(isProcessing) {
    this.setState((state) => ({ ...state, processing: isProcessing }));
  }

  onInputChange(form) {
    this.form = { ...this.form, ...form };
  }

  onDiscountApplied(discountCode, discountedPrice) {
    this.form.discount_code = discountCode;
    this.setState((state) => ({
      ...state,
      discountedPrice,
    }));
  }

  submit(event) {
    event.preventDefault();
    this.setState((state) => ({
      ...state,
      processing: true,
      checkout: null,
      genericError: null,
      validationErrors: {},
      pagseguroErrors: null,
    }));
    const url = `${Config.apiHost}checkouts/pay_with_bank_slip/`;
    this.form.sender_hash = PagSeguroDirectPayment.getSenderHash();
    this.form.voucher_amount = this.props.amount;
    Ajax.post(url, this.form)
      .done((data) => {
        this.setState((state) => ({ ...state, checkout: { ...data } }));
      })
      .fail((jqXHR) => {
        this.handleBackendErrors(jqXHR);
      })
      .always(() => {
        if (this.mounted) {
          this.setState((state) => ({ ...state, processing: false }));
        }
      });
  }

  handleBackendErrors(jqXHR) {
    const { i18n } = this.props;
    const msg = i18n.t(
      'Não foi possível realizar o pagamento. Verique os erros abaixo.'
    );
    this.setState((state) => ({
      ...state,
      validationErrors: jqXHR.responseJSON,
      pagseguroErrors: jqXHR.responseJSON ? jqXHR.responseJSON.pagseguro : null,
      genericError: msg,
      processing: false,
    }));
    HtmlUtils.scrollFirstScrollableToTop(this.nodeRef.current);
  }

  render() {
    if (this.state.pagseguroScriptError) {
      return (
        <div className="tab-content">
          <AlertError>
            <p>
              Não foi possível carregar os meios de pagamento. Por favor, tente
              novamente.
            </p>
          </AlertError>
        </div>
      );
    }

    if (this.state.checkout) {
      // Show getsitecontrol conversion survey
      GetSiteControl.show(399206);
      // Payment confirmation
      return <Redirect to={URLS.paymentConfirmation} />;
    }

    const { i18n } = this.props;

    return (
      <Waiting
        isProcessing={!this.state.pagseguroIsLoaded || this.state.processing}
      >
        <ErrorBoundary featureName={i18n.t('Desconto')}>
          <DiscountField
            plan={this.props.plan}
            onApply={this.onDiscountApplied}
            onProcessing={this.onProcessing}
          />
        </ErrorBoundary>

        <form onSubmit={this.submit} ref={this.nodeRef} autoComplete="fuck-off">
          {(this.state.genericError || this.state.pagseguroErrors) && (
            <AlertError>
              {this.state.genericError}
              {this.state.pagseguroErrors && (
                <ul>
                  {this.state.pagseguroErrors &&
                    this.state.pagseguroErrors.map((m) => (
                      <li>{getPagSeguroMessage(m)}</li>
                    ))}
                </ul>
              )}
            </AlertError>
          )}

          <ErrorBoundary>
            <PagSeguroIdentificationForm
              errors={this.state.validationErrors}
              onChange={this.onInputChange}
            />
          </ErrorBoundary>

          <ErrorBoundary>
            <AddressForm
              title={i18n.t('Endereço de cobrança')}
              errors={this.state.validationErrors}
              onChange={this.onInputChange}
              onProcessing={this.onProcessing}
            />
          </ErrorBoundary>

          <ConfirmCancel processing={this.state.processing} />
        </form>
      </Waiting>
    );
  }
}

PayWithBankSlip.propTypes = {
  plan: PropTypes.object.isRequired,
  onProcessing: PropTypes.func.isRequired,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default withNamespaces()(PayWithBankSlip);
